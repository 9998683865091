import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import {ICartItem} from '../../../../../../types/app.types';
import s from '../../CartItem.scss';
import {ItemLink} from '../ItemLink/ItemLink';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';

export const Name = ({item}: {item: ICartItem}) => {
  const {
    cartStore: {makeCartItemNameLink},
  } = useControllerProps();
  const {name} = item.product;

  if (makeCartItemNameLink) {
    return (
      <p data-hook={CartItemDataHook.Name} className={s.productName}>
        <ItemLink item={item}>{name}</ItemLink>
      </p>
    );
  }

  return (
    <p data-hook={CartItemDataHook.Name} className={s.productName}>
      {name}
    </p>
  );
};
