import React from 'react';
import {ICartItem} from '../../../../../../types/app.types';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';

export const TotalPrice = ({item}: {item: ICartItem}) => {
  return (
    <div
      className={s.totalPrice}
      data-hook={CartItemDataHook.TotalPrice}
      data-wix-item-total-price={CartItemDataHook.TotalPrice}>
      <div className={s.totalPriceText}>{item.convertedPrices.formattedTotalPrice}</div>
    </div>
  );
};
