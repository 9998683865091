import React from 'react';
import {getHref} from '../../../../../../domain/utils/itemUtils';
import {ICartItem} from '../../../../../../types/app.types';
import {useControllerProps, UseControllerPropsResult} from '../../../../../../domain/controllers/ControllerContext';
import {STORES_APP_DEF_ID} from '@wix/wixstores-client-core';

export enum ItemLinkDataHooks {
  Anchor = 'ItemLinkDataHooks.Anchor',
  NonClickableItem = 'ItemLinkDataHooks.NonClickableItem',
}

const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement>,
  navigationStore: UseControllerPropsResult['navigationStore'],
  urlPart: string,
  shouldUsePageUrlOnItemThumbnail: boolean,
  fullUrl: string
) => {
  event.preventDefault();
  event.stopPropagation();
  if (shouldUsePageUrlOnItemThumbnail && fullUrl) {
    return navigationStore.navigateToFullProductUrl(fullUrl);
  }
  return navigationStore.navigateToProduct(urlPart);
};

export const ItemLink = ({
  item,
  children,
  tabIndex,
}: {
  item: ICartItem;
  children: React.ReactChild;
  tabIndex?: number;
}) => {
  const {cartStore, navigationStore} = useControllerProps();
  const {shouldUsePageUrlOnItemThumbnail} = cartStore;
  const href = getHref(cartStore, item);
  const shouldRenderNonClickableItem = Boolean(
    ((item.product.urlPart === '' || item.catalogAppId !== STORES_APP_DEF_ID) && !shouldUsePageUrlOnItemThumbnail) ||
      (shouldUsePageUrlOnItemThumbnail && item.product.urlPart === '' && !item.product.pageUrl)
  );

  return shouldRenderNonClickableItem ? (
    <div data-hook={ItemLinkDataHooks.NonClickableItem}>{children}</div>
  ) : (
    <a
      href={href}
      onClick={(event) =>
        handleClick(event, navigationStore, item.product.urlPart, shouldUsePageUrlOnItemThumbnail, item.product.pageUrl)
      }
      {...(tabIndex !== undefined ? {tabIndex} : {})}
      data-hook={ItemLinkDataHooks.Anchor}>
      {children}
    </a>
  );
};
