/* eslint-disable prefer-named-capture-group */
export const guidToInt = (guid: string): number => {
  const reg = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);

  if (!reg.exec(guid)) {
    return undefined;
  }

  return parseInt(guid.replace(/-/g, ''), 16);
};
