import React from 'react';
import {ICartItem} from '../../../../types/app.types';
import {ProductThumbnail} from '@wix/wixstores-client-common-components';
import s from './CartItemThumbnail.scss';
import {ItemLink} from '../CartItem/partials/ItemLink/ItemLink';
import {getMediaDimensions} from '../../../../domain/utils/itemUtilsWithSideEffect';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';

export enum CartItemThumbnailDataHook {
  Image = 'CartItemThumbnailDataHook.Image',
}

export const CartItemThumbnail = ({item}: {item: ICartItem}) => {
  const {
    cartStore: {makeCartItemNameLink},
  } = useControllerProps();
  return (
    <div className={s.thumbnail} data-hook={CartItemThumbnailDataHook.Image}>
      <ItemLink item={item} tabIndex={makeCartItemNameLink ? -1 : undefined}>
        <ProductThumbnail
          product={item.product}
          {...getMediaDimensions(item, CartItemThumbnail.defaultDimensions)}
          mountedToDOM={true}
        />
      </ItemLink>
    </div>
  );
};

CartItemThumbnail.defaultDimensions = {width: 100, height: 100};
