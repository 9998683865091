import React from 'react';
import {SectionNotification, SectionNotificationType} from 'wix-ui-tpa';
import Alert from 'wix-ui-icons-common/on-stage/Alert';
import Error from 'wix-ui-icons-common/on-stage/Error';
import {Severity, Violation} from '@wix/wixstores-graphql-schema-node';
import {SanitizeInnerHTMLWrapper} from '@wix/wixstores-client-common-components';
import s from './ViolationNotification.scss';
import {useTranslation} from '@wix/yoshi-flow-editor';

export enum ViolationNotificationDataHook {
  Root = 'ViolationNotificationDataHook.Root',
  Message = 'ViolationNotificationDataHook.Message',
  Icon = 'ViolationNotificationDataHook.Icon',
}

export interface ViolationNotificationProps {
  violation: Violation;
}

export const ViolationNotification = ({violation}: ViolationNotificationProps) => {
  const {t} = useTranslation();
  const type = violation.severity === Severity.ERROR ? SectionNotificationType.error : SectionNotificationType.alert;
  const Icon = violation.severity === Severity.ERROR ? Error : Alert;
  const iconClassName = violation.severity === Severity.ERROR ? s.errorIcon : s.alertIcon;
  const srLabel =
    violation.severity === Severity.ERROR
      ? 'sideCart.validationErrorIcon.ariaLabel'
      : 'sideCart.validationWarningIcon.ariaLabel';
  return (
    <SectionNotification type={type} data-hook={ViolationNotificationDataHook.Root}>
      <SectionNotification.Icon icon={<Icon className={iconClassName} />} />
      <SectionNotification.Text>
        <span className={'sr-only'}>{t(srLabel)}</span>
        <SanitizeInnerHTMLWrapper innerHtml={violation.description}>
          <span data-hook={ViolationNotificationDataHook.Message} />
        </SanitizeInnerHTMLWrapper>
      </SectionNotification.Text>
    </SectionNotification>
  );
};
