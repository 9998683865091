import React from 'react';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {ContinueShopping, ContinueShoppingPosition} from '../ContinueShopping/ContinueShopping';
import {Headline} from '../Headline/Headline';
import {HTMLTag} from '../HTMLTag/HTMLTag';
import s from './EmptyState.scss';

export enum EmptyStateDataHook {
  root = 'EmptyState.root',
  title = 'EmptyState.title',
}

export const EmptyState = () => {
  const {get} = useSettings();

  return (
    <>
      <Headline />
      <div className={s.emptyState} data-hook={EmptyStateDataHook.root}>
        <HTMLTag data-hook={EmptyStateDataHook.title} type="h3" className={s.title}>
          {get(settingsParams.CART_EMPTY_CART) as string}
        </HTMLTag>
        <ContinueShopping position={ContinueShoppingPosition.emptyState} />
      </div>
    </>
  );
};
