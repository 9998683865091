import React from 'react';
import s from '../../CartItem.scss';
import {ICartItem} from '../../../../../../types/app.types';
import {CartItemDataHook} from '../../CartItem';
import {PaymentOptionType} from '@wix/wixstores-graphql-schema-node';
import {useTranslation} from '@wix/yoshi-flow-editor';

export const PaymentTypeLabel = ({item}: {item: ICartItem}) => {
  const {formattedDepositAmount, price} = item.convertedPrices;
  const {t} = useTranslation();

  const getLabel = () => {
    const membershipName = item.selectedMembership?.name?.original;
    switch (item.paymentType) {
      case PaymentOptionType.DEPOSIT_ONLINE:
        return t('cart.myCartPaymentMethod.deposit.lineItem', {price: formattedDepositAmount});
      case PaymentOptionType.MEMBERSHIP:
        return membershipName
          ? t('cart.myCartPaymentMethod.onlinePlan.lineItem', {membership: membershipName})
          : t('checkout.myCartPaymentMethod.payWithPlan.lineItem');
      case PaymentOptionType.MEMBERSHIP_OFFLINE:
        return membershipName
          ? t('cart.myCartPaymentMethod.offlinePlan.lineItemWithId', {membership: membershipName})
          : t('cart.myCartPaymentMethod.offlinePlan.lineItemWithoutId');
      case PaymentOptionType.FULL_PAYMENT_OFFLINE:
        return price > 0 && t('cart.myCartPaymentMethod.offlinePayment.lineItem');
      /* istanbul ignore next */
      default:
        return '';
    }
  };

  return (
    <div>
      <span data-hook={CartItemDataHook.PaymentTypeLabel} className={s.paymentLabel}>
        {getLabel()}
      </span>
    </div>
  );
};
